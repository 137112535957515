var $ = require( "jquery" )

require("slick-carousel")

import "slick-carousel/slick/slick.scss"

window.addEventListener("DOMContentLoaded", function () {

  // the navbar-toggler is a permanent element, so we need to add the event listener here
  const navbarTogglers = document.querySelectorAll(".navbar-toggler");
  navbarTogglers.forEach(function(toggler) {
    toggler.addEventListener("click", function () {
      navbarTogglers.forEach(function(t) {
        t.classList.toggle("show");
      });
      document.querySelector(".navbar-nav").classList.toggle("show");
      document.body.classList.toggle("add-fix");
    });
  });

  document.addEventListener("turbolinks:load", function() {
    // Function to close navbar
    function closeNavbar() {
      const navbar = document.querySelector(".navbar-nav");
      const navbarTogglers = document.querySelectorAll(".navbar-toggler");
      if (navbar && navbar.classList.contains("show")) {
        navbar.classList.remove("show");
      }
      navbarTogglers.forEach(toggler => {
        if (toggler.classList.contains("show")) {
          toggler.classList.remove("show");
        }
      });
      if (document.body.classList.contains("add-fix")) {
        document.body.classList.remove("add-fix");
      }
    }

    // Close navbar on turbolinks:load
    closeNavbar();

    // /* **** Hero Slider **** */
    $(".hero-slider").slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1000,
      fade: true,
      cssEase: "linear",
      prevArrow: "<button type='button' class='slick-prev slick-arrow'><i class='fal fa-chevron-left'></i></button>",
      nextArrow: "<button type='button' class='slick-next slick-arrow'><i class='fal fa-chevron-right'></i></button>",
      responsive: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
              arrows: false,
          },
        },
      ],
    });
    // /* **** End Hero Slider **** */

    // /* **** Hero Slider **** */
    $(".fresh-slider").slick({
        infinite: true,
        slidesToShow: 3,
        prevArrow: "<button type='button' class='slick-prev slick-arrow'><i class='fal fa-chevron-left'></i></button>",
        nextArrow: "<button type='button' class='slick-next slick-arrow'><i class='fal fa-chevron-right'></i></button>",
        responsive: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    centerMode: true,
                    arrows: false,
                    centerPadding: "210px 0 0",
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 447,
                settings: {
                    centerMode: true,
                    arrows: false,
                    centerPadding: "150px 0 0",
                    slidesToShow: 1,
                },
            },
        ],
    });
    // /* **** End Hero Slider **** */

    // /* **** Hero Slider **** */
    $(".products-slider").slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: "<button type='button' class='slick-prev slick-arrow'><i class='far fa-chevron-left'></i></button>",
        nextArrow: "<button type='button' class='slick-next slick-arrow'><i class='far fa-chevron-right'></i></button>",
        responsive: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    centerMode: true,
                    arrows: false,
                    centerPadding: "210px 0 0",
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 447,
                settings: {
                    centerMode: true,
                    arrows: false,
                    centerPadding: "150px 0 0",
                    slidesToShow: 1,
                },
            },
        ],
    });
    // /* **** End Hero Slider **** */

    // /* **** Product Slider **** */


    // $(".slider-nav").slick("slickGoTo", 1);
    // /* **** End Product Slider **** */

    // /* ***** Quantity Inputs **** */
    $(".quantity").on("click", ".plus", function (e) {
        let $input = $(this).prev("input.qty");
        let val = parseInt($input.val());
        $input.val(val + 1).change();
    });

    $(".quantity").on("click", ".minus", function (e) {
        let $input = $(this).next("input.qty");
        var val = parseInt($input.val());
        if (val > 1) {
            $input.val(val - 1).change();
        }
    });
    // /* ***** End Quantity Inputs **** */

    // /* **** Fix Header **** */
    $(window).scroll(function () {
        if ($(this).scrollTop() > 218) {
            $("header").addClass("sticky");
        } else {
            $("header").removeClass("sticky");
        }
    });
    // /* **** End Fix Header **** */

    // /* **** Fix Header **** */
    $(window).scroll(function () {
        if ($(this).scrollTop() > 164) {
            $(".top-head").addClass("top");
        } else {
            $(".top-head").removeClass("top");
        }
    });


    document.querySelectorAll(".accordion-header").forEach((header) => {
        header.addEventListener("click", () => {
            const content = header.nextElementSibling;
            content.classList.toggle("show");
        });
    });

    // Product Gallery
    function initializeProductGallery() {
        const imageTabs = document.querySelectorAll('[id^="image-tab-"]');
        const imagePanels = document.querySelectorAll('[id^="image-panel-"]');

        if (imageTabs.length === 0 || imagePanels.length === 0) {
            // No images or only one image, no need to initialize gallery
            return;
        }

        function setActiveImage(tab) {
            imagePanels.forEach(panel => panel.classList.add('hidden'));
            imageTabs.forEach(t => t.querySelector('span:last-child').classList.remove('ring-foliage'));

            const panelId = tab.getAttribute('aria-controls');
            const panel = document.getElementById(panelId);
            if (panel) {
                panel.classList.remove('hidden');
            }
            tab.querySelector('span:last-child').classList.add('ring-foliage');
        }

        imageTabs.forEach(tab => {
            tab.addEventListener('click', () => setActiveImage(tab));
        });

        // Handle option selection
        const optionRadios = document.querySelectorAll('.product-variants-variant-values-radio');
        optionRadios.forEach(radio => {
            radio.addEventListener('change', () => {
                const variantId = radio.dataset.variantId;
                const matchingTab = document.querySelector(`[data-variant-id="${variantId}"]`);
                if (matchingTab) {
                    setActiveImage(matchingTab);
                }
            });
        });

        // Set initial active image based on the selected option
        const initialSelectedRadio = document.querySelector('.product-variants-variant-values-radio:checked');
        if (initialSelectedRadio) {
            const initialVariantId = initialSelectedRadio.dataset.variantId;
            const initialMatchingTab = document.querySelector(`[data-variant-id="${initialVariantId}"]`);
            if (initialMatchingTab) {
                setActiveImage(initialMatchingTab);
            }
        } else {
            // If no option is selected, show the first image
            setActiveImage(imageTabs[0]);
        }
    }

    // Initialize the product gallery
    initializeProductGallery();

    // /* **** Add Remove Class **** * /

    // /* **** End Add Remove Class **** */
    $(".accordion-header").click(function () {
        $(this).toggleClass("show");
    });

    /* **** Tabs **** */
    // This is not in use and raising exception
    // let tabsContainer = document.querySelector("#tabs");
    // let tabTogglers = tabsContainer.querySelectorAll("#tabs a");
    // console.log(tabTogglers);
    // tabTogglers.forEach(function (toggler) {
    //     toggler.addEventListener("click", function (e) {
    //         e.preventDefault();
    //         let tabName = this.getAttribute("href");
    //         let tabContents = document.querySelector("#tab-contents");
    //         for (let i = 0; i < tabContents.children.length; i++) {
    //             tabTogglers[i].parentElement.classList.remove("border-darker-foliage", "text-darker-foliage", "border-transparent");
    //             tabContents.children[i].classList.remove("hidden");
    //             if ("#" + tabContents.children[i].id === tabName) {
    //                 continue;
    //             }
    //             tabContents.children[i].classList.add("hidden");
    //         }
    //         e.target.parentElement.classList.add("border-darker-foliage", "text-darker-foliage");
    //     });
    // });
    /* **** End Tabs **** */
  });
});
